


























































































import { Vue, Component } from 'vue-property-decorator'
import reportService, { ProfitCalc } from '@/services/reportService'

@Component
export default class Profit extends Vue {
  stockData: ProfitCalc = null
  optionData: ProfitCalc = null
  search: { selectedSymbols: string[]; start: string; end: string; contractType: string[] } = {
    selectedSymbols: ['AMZN'],
    start: null,
    end: null,
    contractType: ['Stock']
  }

  async mounted () {
    await this.loadData()
  }

  $num (num: number, digits = 2): string {
    if (num != null && num !== undefined) {
      return num.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: digits })
    }
    return null
  }

  async loadData () {
    const res = await reportService.getDayProfits(this.search.selectedSymbols, this.search.start, this.search.end, this.search.contractType)
    this.stockData = res.Result.Stock
    this.optionData = res.Result.Option
  }
}
